<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header column">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.station") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.certificate.select") }} {{ $t("workspace.certificate.station") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.name") }}</label>
            <input type="text" v-model="filterModel.name" :placeholder="`${$t('workspace.certificate.enter')} ${$t('workspace.client.name')}`">
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.agency") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.certificate.select") }} {{ $t("workspace.certificate.agency") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.num_ord") }}</label>
            <input type="text" v-model="filterModel.name" :placeholder="`${$t('workspace.certificate.enter')} ${$t('workspace.client.num_ord')}`">
          </div>
          <div class="settings-input">
            <label>{{ $t('workspace.certificate.num_int') }}</label>
            <input type="text" v-model="filterModel.name" :placeholder="`${$t('workspace.certificate.enter')} ${$t('workspace.certificate.num_int')}`">
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.client") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.certificate.select") }} {{ $t("workspace.certificate.client") }}</option>  
            </select> 
          </div>
        </div>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.dates") }}</label>
            <select name="" id="">
              <option value="" selected>Rango de fechas</option>  
              <option value="">Periodo</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.from") }}</label>
            <input type="date" v-model="filterModel.name">
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.certificate.to") }}</label>
            <input type="date" v-model="filterModel.name">
          </div>
          <button :disabled="filterModel.code == '' && filterModel.name == ''">{{ $t("workspace.certificate.search") }}</button>
        </div>
      </div>
    </div>

    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.certificate.station") }}</th>
          <th>{{ $t("workspace.certificate.agency") }}</th>
          <th>{{ $t("workspace.certificate.customer") }}</th>
          <th>{{ $t("workspace.certificate.name") }}</th>
          <th>{{ $t("workspace.certificate.no_int") }}</th>
          <th>{{ $t("workspace.certificate.no_agency") }}</th>
          <th>{{ $t("workspace.certificate.init") }}</th>
          <th>{{ $t("workspace.certificate.end") }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr> -->
      </tbody>
    </table>

    <div class="workspace__container__view__actions">
      <button>{{ $t("workspace.certificate.certificate") }}</button>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  setup() {
    const filterModel = ref({
      code: '',
      name: ''
    })

    
    return {
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>